import React, { useEffect, useRef } from "react";
import { dsnCN } from "../../hooks/helper";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { splittingItems } from "../../hooks/Spltting";
import { SiUpwork } from "react-icons/si";

function SocialMedia({ className }) {
  const ref = useRef();
  const socialData = [
    { link: "https://www.linkedin.com/company/dataprismsol/", icon: faLinkedin },
    { link: "https://twitter.com/prism_data", icon: faTwitter },
    { link: "https://www.upwork.com/o/companies/~01f58a5b50de53f022/", icon: SiUpwork },
  ];

  useEffect(() => {
    splittingItems(ref.current, "li");
  });

  return (
    <ul className={dsnCN("social-one", className)} ref={ref}>
      {socialData.map((item, index) => (
        <li key={index}>
          <a href={item.link} target="_blank" rel="noopener noreferrer" title={item.link}>
            {index === 2 ? <SiUpwork size={20}/> : <FontAwesomeIcon size={20} fontSize={20} icon={item.icon} />}
          </a>
        </li>
      ))}
    </ul>
  );
}

export default SocialMedia;
