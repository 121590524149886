import React from "react";
import { dsnCN } from "../../../hooks/helper";
import SocialMedia from "../../social-media/SocialMedia";
function MenuContent({ className }) {
  const socialData = [
    {
      link: "https://www.linkedin.com/company/dataprismsol/",
      name: "Linkedin",
    },
    { link: "https://twitter.com/prism_data", name: "Twitter" },
  ];
  return (
    <div
      className={dsnCN(
        "container-content  d-flex flex-column justify-content-center",
        className
      )}
    >
      <div className="nav__info">
        <div className="nav-content">
          <h5 className="sm-title-block mb-10">Office</h5>
          <p>
            304 Iqbal Heights, 48 Block C<br /> Pia Housing Scheme, Lahore
          </p>
        </div>
        <div className="nav-content mt-30">
          <h5 className="sm-title-block mb-10">Contact</h5>
          <p className="links over-hidden mb-1">
            <a
              className="link-hover"
              href="tel:(+92) 042-35199443"
              data-hover-text="042-35199443"
            >
              042-35199443
            </a>
          </p>
          <p className="links over-hidden">
            <a
              className="link-hover"
              href="mailto:info@dsngrid.com"
              data-hover-text="info@dsngrid.com"
            >
              usman@dataprism.me
            </a>
          </p>
        </div>
      </div>
      <div className="nav-social nav-content mt-30">
        <div className="nav-social-inner p-relative">
          <h5 className="sm-title-block mb-10">Follow us</h5>
          {/* <ul>
            {socialData.map((item, index) => (
              <li key={index}>
                <a
                  href={item.link}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul> */}
          <SocialMedia className={'social-icon'}/>
        </div>
      </div>
    </div>
  );
}

export default MenuContent;
