import React from "react";
import styled from "styled-components";
import brandLight from './logo.png';
import logo from "../../assets/dplogo.png"
import brandDark from './logo-dark.png';

const MainBrand = styled.div`
   img{
    width : ${props => props.width};
    max-width: 100%;
    height: ${props => props.height};
   }
   .v-light & , & {
        .logo-dark{
           display : block ;     
        }
        .logo-light{
            display : none;    
        }
   }
    
   .v-dark & {
       .logo-dark{
           display : none ;     
        }
        .logo-light{
            display : block;    
        }
   }
   
`;

const Brand = ({ width, height, alt }) => {
    return (
        <MainBrand className="main-brand" width="180px" height="100%">
            <img className="logo-light" src={logo} alt={`Data Prism`} width={width} height={height} />
            {/* <img className="logo-dark" src={logo} alt={`${alt} - logo dark`} width={width} height={height}/> */}
        </MainBrand>
    );
}

MainBrand.defaultProps = { width: '100px', height: 'auto' }
Brand.defaultProps = { alt: "Data Prism" }

export default React.memo(Brand);