import React, { Suspense, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import useEffectLocation from "../hooks/useEffectLocation";

import "./style.scss";
const M4M = React.lazy(() => import("../views/portfolio/M4M"));
const Main = React.lazy(() => import("../views/home/Main"));
const Work = React.lazy(() => import("../views/Work"));
const WorkDetails = React.lazy(() => import("../views/portfolio/WorkDetail"));
const About = React.lazy(() => import("../views/About"));
const Services = React.lazy(() => import("../views/Services"));


const Router = () => {
  const [transPage, setTransPage] = useState("in");
  const scrollbar = useSelector((state) => state.scrollbar);

  const location = useEffectLocation(() => {
    setTransPage("out");
  });

  const onAnimateEnd = () => {
    if (transPage !== "out") return;
    scrollbar.current?.scrollTo(0, 0, 0);
    window.scrollTo({ left: 0, top: 0 });
    setTransPage("in");
  };

  return (
    <div
      id="dsn-content"
      className={`dsn-transition-page dsn-animate-${transPage}`}
      onAnimationEnd={onAnimateEnd}
    >
      <Suspense fallback={<div className="background-main h-100-v" />}>
        <Routes location={location}>
          <Route path="/" element={<Main />} />
          <Route exact path="/portfolio" element={<Work />} />
          <Route exact path="/portfolio/:slug" element={<WorkDetails />} />
          <Route exact path="/case-study/:slug" element={<M4M />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default React.memo(Router);
